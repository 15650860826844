<template>
  <div class="hello">
    <div class="container mt-3 mb-3" >
      <a href="/infospecial/0"
        ><div style="text-align: right">
          <i
            class="fa fa-plus-circle"
            style="font-size: 36px; color: black"
          ></i></div
      ></a>

      <div class="list-group mt-3">
        <li
          v-for="(l, i) in list"
          :key="i"
          href="#"
          :class="
            'list-group-item d-flex justify-content-between align-items-center ' +
            l.style
          "
        >
          {{ l.content }}
          <a :href="'/infospecial/' + l.special_id"
            ><span class="badge badge-pill"
              ><i class="fa fa-edit" style="font-size: 24px; color: black"></i
            ></span>
          </a>
        </li>
      </div>
    </div>
  </div>
</template>

<script>
import Special from "../services/Special";
import ConvertService from "../services/ConvertService";

export default {
  data() {
    return {
      list: [],
      colour: [
        "list-group-item-primary",
        "list-group-item-secondary",
        "list-group-item-success",
        "list-group-item-danger",
        "list-group-item-warning",
        "list-group-item-info",
        "list-group-item-light",
        "list-group-item-dark]",
      ],
      json: {},
    };
  },
  methods: {
    loaddata() {
      Special.getSpecials().then((res) => {
        this.list = res.data;
        for (let ll = 0; ll < this.list.length; ll++) {
        // this.list[ll].style = this.colour[Math.floor(Math.random() * this.colour.length)]            

          // console.log(this.list);
        }
      });
    },
    convertdate(date) {
      return ConvertService.convertdatetothai(date,"th-TH");
    },
    start() {
      // localStorage.removeItem("keys");
      // var item = localStorage.getItem("keys");
      // this.json = JSON.parse(item);
      // if (this.json) {
    this.loaddata();
      // }
    },
  },

  mounted() {
    this.start();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
