import http from "../http-common";

class TypeService {
  getTypes() {
    return http.get('/type');
  }
  getType(id) {
    return http.get(`/type/${id}`);
  }
  createContent(data) {
    return http.post('/type', data);
  }
  updateContent(id,data) {
    return http.put(`/type/${id}`, data);
  }
}

export default new TypeService();