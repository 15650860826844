import http from "../http-common";

class DatabasedService {
  getdatabaseds(name) {
    return http.get('/databased?name='+name);
  }
  getdatabased(id) {
    return http.get(`/databased/${id}`);
  }
  createdatabased(data) {
    return http.post('/databased', data);
  }
  updatedatabased(id,data) {
    return http.put(`/databased/${id}`, data);
  }  
  updatestatus(id,data) {
    return http.put(`/databased/updatestatus/${id}`, data);
  }
  deletedatabased(id) {
    return http.delete(`/databased/${id}`);
  }
}

export default new DatabasedService();