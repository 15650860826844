<template>
  <div id="app container">
    <Nav />
    <router-view />
  </div>
</template>

<script>
import Nav from './components/Nav.vue'

export default {
  name: 'App',
  components: {
    Nav
  },
  data() {
    return {
      password: "",
    }
  },
  methods: {
    go() {
      if (this.password == 110497) {
        var d = new Date();
        var v = new Date();
        v.setMinutes(d.getMinutes() + 30);

        console.log(v);
        localStorage.setItem("keys", JSON.stringify({ status: true, time: v }));
      } else {
        alert("รหัสไม่ถูกต้อง");
      }
    },
    start() {
      var item = localStorage.getItem("keys");
      this.json = JSON.parse(item);
      if (!item) {
        document.getElementById("myCheck").click();
      } else {
        console.log(new Date(this.json.time), "0", new Date());
        if (new Date() >= new Date(this.json.time)) {
          localStorage.removeItem("keys");
        }
      }
    },
  },
  mounted() {    
      localStorage.removeItem("keys");
    // this.start();
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

a{
    text-decoration: none !important;
}
</style>
