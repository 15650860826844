import http from "../http-common";

class PlanService {
  getplans() {
    return http.get('/plans');
  }
  getplan(id) {
    return http.get(`/plans/${id}`);
  }
  createplan(data) {
    return http.post('/plans', data);
  }
  updateplan(id,data) {
    return http.put(`/plans/${id}`, data);
  }
  deleteplan(id) {
    return http.delete(`/plans/${id}`);
  }
}

export default new PlanService();