<template>
  <div class="detail container">
    <div class="form-group">
      <label for="exampleFormControlInput1">วันที่</label>
      <!-- <input id="date" class="form-control" /> -->
      <input type="date" class="form-control" />
    </div>
    <div class="form-group">
      <label for="exampleFormControlTextarea1">รายละเอียด</label>
      <textarea
        v-model="description"
        class="form-control"
        id="exampleFormControlTextarea1"
        rows="20"
      ></textarea>
    </div>
    <button class="btn btn-warning" style="color:white" @click="back()">back</button>&nbsp;
    <button class="btn btn-success" @click="save()">save</button>
  </div>
</template>
  <script src="https://api-memoirs.ponnipa.in.th/picker_date.js"></script>

<script>
import Content from "../services/Content";
import ConvertService from "../services/ConvertService";

export default {
  name: "info",
  data() {
    return {
      id: "",
      description: "",
    };
  },
  methods: {
    back() {
      this.$router.push("/list");
    },
    save() {
      var value = {
        date: ConvertService.createddate(document.getElementById("date").value),
        description: this.description,
      };
      if (this.id != 0) {
        Content.updateContent(this.id, value).then(() => {
          // console.log(res.data);
          this.$router.push("/list");
        });
      } else {
        Content.createContent(value)
          .then(() => {
            // console.log(res.data);
            this.$router.push("/list");
          })
          .catch((e) => {
            if (e) {
              alert("วันที่นี้มีในระบบแล้ว");
            }
          });
      }
    },
  },
  mounted() {
    picker_date(document.getElementById("date"), { year_range: "-12:+10" });
    this.id = this.$route.params.id;
    if (this.id != 0) {
      Content.getContent(this.id).then((res) => {
        // console.log(res.data);
        document.getElementById("date").value = ConvertService.getdate(
          res.data.date
        );
        this.description = res.data.description;
      });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
