<template>
  <div class="hello">
    <div class="container mt-5">
    <div style="text-align: right">
      <button id="AddDataset" @click="getid(0)" type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#exampleModal">
        <i class="fa fa-plus" aria-hidden="true"></i>
</button>
         </div
      >
      <table class="table table-striped">
  <thead>
    <tr>
      <th scope="col">Dataset</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(l, i) in list" :key="i">
      <td scope="row" style="vertical-align: middle;">{{l.name}}</td>
      <td style="vertical-align: middle;"> <div class="form-check form-switch">
  <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="l.status" @change="savestatus(l.id,l.status)">
</div></td>
      <td scope="row"><button type="button" class="btn btn-secondary" @click="getid(l.id)" data-bs-toggle="modal" data-bs-target="#exampleModal">Edit</button></td>
    </tr>
  </tbody>
</table>
    </div>

    <!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{title}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group row">
              <label for="staticEmail" class="col-sm-12 col-form-label"
                >Dataset
              </label>
              <div class="col-sm-10">
                <input
                  id="date_calendar"
                  class="form-control"
                  v-model="data.name"
                />
               
              </div>
            </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" @click="save()">Save</button>
        <button type="button" id="close" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
  </div>

  
</template>

<script>
import DatasetService from "../services/DatasetService";

export default {
  data() {
    return {
      data:{},
      id:0,
      title:'',
      list: [],
      json: {},
    };
  },
  methods: {
    savestatus(id,status){
      var data = {
        status:status
      }
        DatasetService.updatestatus(id,data).then(() => {
          // alert('บันทึกสำเร็จ')
          this.getdata();
        });
      
// console.log(id,active);
    },
    getid(id){
      this.id = id
      console.log(this.id);
      if (this.id != 0) {
        DatasetService.getdataset(this.id).then((res)=>{
        this.data = res.data
      })
      }else{
        this.id = 0
this.data = {}
      }
     
    },
    save(){
      if (this.data.name == null || this.data.name == '') {
        alert('กรุณากรอก Dataset')
      }else{
var data = {
  name : this.data.name,
  status:1
}
console.log(data);
if (this.id != 0) {
  DatasetService.updatedataset(this.id,data).then(()=>{
    document.getElementById("close").click();
    this.getdata()
  })
}else{
DatasetService.createdataset(data).then(()=>{
  document.getElementById("close").click();
  this.getdata()
  })
}
}
    },
    getdata() {
      DatasetService.getdatasets('').then((res)=>{
        this.list = res.data
      })
    },
  },

  mounted() {  
    this.getdata();    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
