<template>
  <div class="detail container">
    <div class="form-group">
      <label for="exampleFormControlTextarea1">รายละเอียด</label>
      <textarea
        v-model="content"
        class="form-control"
        id="exampleFormControlTextarea1"
        rows="5"
      ></textarea>
    </div>
    <button class="btn btn-warning" style="color: white" @click="back()">
      back</button
    >&nbsp; <button class="btn btn-success" @click="save()">save</button>&nbsp;
    <button class="btn btn-danger" style="color: white" @click="deletedata()">
      delete
    </button>
  </div>
</template>
  <script src="https://api-memoirs.ponnipa.in.th/picker_date.js"></script>

<script>
import Special from "../services/Special";

export default {
  name: "info",
  data() {
    return {
      id: "",
      content: "",
    };
  },
  methods: {
    back() {
      this.$router.push("/special");
    },
    deletedata() {
      Special.daleteSpecial(this.id).then(() => {
        // console.log(res.data);
        this.$router.push("/special");
      });
    },
    save() {
      var value = {
        content: this.content,
      };
      if (this.id != 0) {
        Special.updateSpecial(this.id, value).then(() => {
          // console.log(res.data);
          this.$router.push("/special");
        });
      } else {
        Special.createSpecial(value).then(() => {
          // console.log(res.data);
          this.$router.push("/special");
        });
      }
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    if (this.id != 0) {
      Special.getSpecial(this.id).then((res) => {
        this.content = res.data.content;
      });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
