
<template>
  <div class="text-center section">
    <!-- <v-calendar :attributes="attrs" />
    <v-calendar is-expanded :attributes="attrs"/> -->
    <!-- <div class="container">
      <a @click="adddata()"
        ><div style="text-align: right">
          <i
            class="fa fa-plus-circle"
            style="font-size: 36px; color: black"
          ></i></div
      ></a>
    </div> -->
    <v-calendar
      class="custom-calendar max-w-full"
      :masks="masks"
      :attributes="attributes"
      disable-page-swipe
    >
      <div
        slot="day-content"
        v-on="dayEvents"
        slot-scope="{ day, attributes }"
        class="flex flex-col h-full z-10 overflow-hidden"
        :class="day.year"
      >
        <span
          class="day-label text-sm text-gray-900"
          :class="[day.dateTime === today ? 'today rounded-sm' : '']"
          >{{ day.day }}</span
        >
        <div class="flex-grow overflow-y-scroll overflow-x-auto">
          <p
            v-for="attr in attributes"
            :key="attr.key"
            class="text-xs leading-tight rounded-sm p-1 mt-0 mb-1"
            :class="attr.customData.class"
          >
            {{ attr.customData.title }}
          </p>
        </div>
      </div>
    </v-calendar>
    <button
      id="addcalendar"
      type="button"
      class="btn btn-primary"
      data-bs-toggle="modal"
      data-bs-target="#AddCalendar"
      style="display: none"
    >
      Launch demo modal
    </button>
    <div
      class="modal fade"
      id="AddCalendar"
      tabindex="-1"
      role="dialog"
      aria-labelledby="AddCalendarLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="AddCalendarLabel"></h5>
            <button
              id="closecalendar"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label for="staticEmail" class="col-sm-2 col-form-label"
                >Date :
              </label>
              <div class="col-sm-10">
                <input
                  v-if="calendar_id == 0"
                  id="date_calendar"
                  class="form-control"
                />
                <input
                  v-else
                  type="text"
                  readonly
                  class="form-control-plaintext"
                  id="staticEmail"
                  :value="datename"
                />
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPassword" class="col-sm-2 col-form-label"
                >Description</label
              >
              <div class="col-sm-10">
                <input
                  v-model="description"
                  type="text"
                  class="form-control"
                  id="inputPassword"
                />
              </div>
            </div>
            <button class="btn btn-success" @click="save()">Save</button>&nbsp;
            <button
              class="btn btn-danger"
              v-if="calendar_id != 0"
              @click="deletedata()"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  <script src="https://api-memoirs.ponnipa.in.th/picker_date.js"></script>

<script>
import Calendar from "../services/Calendar.js";
import ConvertService from "../services/ConvertService";

export default {
  name: "home",
  data() {
    const now = new Date();
    const month = now.getMonth();
    const year = now.getFullYear();
    const day = now.getDate();
    return {
      date: "",
      datename: "",
      description: "",
      calendar_id: 0,
      today: new Date(year, month, day) * 1,
      masks: {
        weekdays: "WWW",
      },
      attributes: [],
      colour: "",
      dayEvents: {
        click: () => {
          // eslint-disable-next-line no-console
          // console.log("dayEvents:", a);
        },
      },
    };
  },
  methods: {
    dayClick() {
      // eslint-disable-next-line no-console
      // console.log("origin DOM click", a);
    },
    loaddata() {
      Calendar.getCalendars("").then((res) => {
        // console.log(res.data);
        for (let c = 0; c < res.data.length; c++) {
          this.attributes.push({
            key: res.data[c].calendar_id,
            customData: { title: res.data[c].title, class: res.data[c].class },
            // dates: { start: new Date(res.data[c].date), end: new Date(2022, 30, 10) },
            dates: new Date(res.data[c].date),
          });
        }
      });
    },
    edit(data) {
      this.calendar_id = data;
      this.getdate();
    },
    deletedata() {
      Calendar.deleteCalendar(this.calendar_id).then((res) => {
        document.getElementById("closecalendar").click();
        setTimeout(function () {
          location.reload();
          this.loaddata();
        }, 500);
      });
    },
    getdate() {
      // this.date = new Date(y, m, d);
      // this.date = new Date(this.date.setMonth(this.date.getMonth() - 1));
      // this.date = new Date(this.date.setDate(this.date.getDate() + 1));
      document.getElementById("addcalendar").click();
      if (this.calendar_id != 0) {
        Calendar.getCalendar(this.calendar_id).then((res) => {
          this.description = res.data.title;
          this.date = res.data.date;
          this.datename = ConvertService.convertdatetothai(
            new Date(res.data.date),
            "th-TH"
          );
        });
      } else {
        this.description = "";
      }
    },
    save() {
      var value = {
        title: this.description,
      };
      console.log(value);
      if (this.calendar_id != 0) {
        Calendar.updateCalendar(this.calendar_id, value).then(() => {
          // console.log(res.data);
          this.loaddata();
          document.getElementById("closecalendar").click();
        });
      } else if (document.getElementById("date_calendar").value == "") {
        alert("กรุณาเลือกวันที่");
      } else {
        var date = new Date(
          ConvertService.createddate(
            document.getElementById("date_calendar").value
          )
        );
        console.log(date);
        var day = date.toLocaleString("en-us", { weekday: "short" });
        // console.log(day);
        if (day == "Mon") {
          this.colour = "bg-yellow-500 text-white";
        } else if (day == "Tue") {
          this.colour = "bg-pink-500 text-white";
        } else if (day == "Wed") {
          this.colour = "bg-green-500 text-white";
        } else if (day == "Thu") {
          this.colour = "bg-orange-500 text-white";
        } else if (day == "Fri") {
          this.colour = "bg-blue-500 text-white";
        } else if (day == "Sat") {
          this.colour = "bg-purple-500 text-white";
        } else if (day == "Sun") {
          this.colour = "bg-red-500 text-white";
        }
        // console.log(this.colour);
        var create = {
          date: ConvertService.createddate(
            document.getElementById("date_calendar").value
          ),
          title: this.description,
          class: this.colour,
        };
        Calendar.createCalendar(create).then(() => {
          // console.log(res.data);
          this.loaddata();
          document.getElementById("closecalendar").click();
        });
      }
    },
    adddata() {
      this.calendar_id = 0;
      this.description = "";
      // document.getElementById("date_calendar").value = null;
      document.getElementById("addcalendar").click();
      
    },
  },
  mounted() {
    this.loaddata();
    picker_date(document.getElementById("date_calendar"), {
      year_range: "-12:+10",
    });
  },
};
</script>

<style>
.flex-col {
  flex-direction: column;
}

.flex {
  display: flex;
}
.flex-grow {
  flex-grow: 1;
}
.h-full {
  height: 100%;
}
.today {
  background-color: #d2e1ec;
}
.bg-yellow-500 {
  background-color: rgb(253 224 71);
}
.bg-pink-500 {
  background-color: rgb(236 72 153);
}
.bg-green-500 {
  background-color: rgb(34 197 94);
}
.bg-orange-500 {
  background-color: rgb(249 115 22);
}
.bg-blue-500 {
  background-color: rgb(56 189 248);
}
.bg-purple-500 {
  background-color: rgb(168 85 247);
}
.bg-red-500 {
  background-color: rgb(239 68 68);
}
.overflow-y-scroll {
  overflow-y: scroll;
}
.overflow-x-auto {
  overflow-x: auto;
}
.z-10 {
  z-index: 10;
}
.overflow-hidden {
  overflow: hidden;
}
.text-gray-900 {
  color: #1a202c;
}
.vc-h-full {
  height: 100%;
}
.custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: auto;
}
.custom-calendar.vc-container .vc-weeks {
  padding: 0;
  height: 80vh;
  grid-template-rows: 33px repeat(6, 1fr);
}
.custom-calendar.vc-container .vc-header {
  background-color: #f1f5f8;
  padding: 10px 0;
}
.custom-calendar.vc-container .vc-weekday {
  background-color: var(--weekday-bg);
  border-bottom: var(--weekday-border);
  border-top: var(--weekday-border);
  padding: 5px 0;
}
.vc-border {
  border-width: 1px;
}
.custom-calendar.vc-container .vc-day:not(.on-right) {
  border-right: var(--day-border);
}
.custom-calendar.vc-container .vc-day:not(.on-bottom) {
  border-bottom: var(--day-border);
}
.custom-calendar.vc-container .vc-day.weekday-1,
.custom-calendar.vc-container .vc-day.weekday-7 {
  background-color: #eff8ff;
}

.custom-calendar.vc-container .vc-day {
  padding: 0 5px 3px;
  text-align: left;
  /*min-height: var(--day-height);*/
  min-width: var(--day-width);
  background-color: #fff;
}
.vc-day {
  position: relative;
  min-height: var(--day-min-height);
  width: 100%;
  height: 100%;
  z-index: 1;
}
.text-center {
  text-align: center;
}
.max-w-full {
  max-width: 100%;
}
.bg-blue-500 {
  background-color: #4299e1;
}
.bg-green-500 {
  background-color: rgb(34 197 94);
}
.text-xs {
  font-size: 0.75rem;
}
.text-white {
  color: #fff;
}
.p-1 {
  padding: 0.25rem;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mt-0 {
  margin-top: 0;
}
.leading-tight {
  line-height: 1.25;
}
.rounded-sm {
  border-radius: 0.125rem;
}
::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  display: none;
}
</style>

  // data() {
  //    const date = new Date();
  // const year = date.getFullYear();
  // const month = date.getMonth();
  // return {
  //   attrs: [
  //     {
  //       key: 'today',
  //       highlight: {
  //         start: { fillMode: 'outline' },
  //           base: { fillMode: 'light' },
  //           end: { fillMode: 'outline' },
  //       },
  //       dates: { start: new Date(year, month, 1), end: new Date(year, month, 10) },
  //     },
  //     {
  //       highlight: {
  //         color: 'green',
  //         fillMode: 'light',
  //       },
  //       dates: new Date(year, month, 13),
  //     },
  //     {
  //       highlight: {
  //         color: 'purple',
  //         fillMode: 'outline',
  //       },
  //       dates: new Date(year, month, 14),
  //     },
  //   ],
  // };
  // },
  