import http from "../http-common";

class DatasetService {
  getdatasets(name) {
    return http.get('/datasets?name='+name);
  }
  getdataset(id) {
    return http.get(`/datasets/${id}`);
  }
  createdataset(data) {
    return http.post('/datasets', data);
  }
  updatedataset(id,data) {
    return http.put(`/datasets/${id}`, data);
  }
  updatestatus(id,data) {
    return http.put(`/datasets/updatestatus/${id}`, data);
  }
  deletedataset(id) {
    return http.delete(`/datasets/${id}`);
  }
}

export default new DatasetService();