import http from "../../http-common";

class AuthService {
  async login(user) {
    // console.log(user)
    // localStorage.setItem('users', JSON.stringify(user));
    // return user
    if (user.username == 'oum' && user.password == '110497') {
var data = {
  id:1
}
          localStorage.setItem('planoum', JSON.stringify(data));
        return data;
      
      }else {
          this.$router.push("/login");
          alert('ชื่อหรือรหัสผ่านไม่ถูกต้อง')
        }
      
  }

  logout() {
    localStorage.removeItem('planoum');
  }

  role() {
    return http.get('role');
  }

  getpage(id) {
    return http.get(`menu/menubyuserid/`+id);
  }

  
  getname(id) {
    return http.get(`user/`+id);
  }

}
export default new AuthService();