<template>
  <div class="hello">
    <div class="container mt-3">
      <div class="input-group mb-3 mt-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="inputGroupSelect01">Month</label>
        </div>
        <select
          class="custom-select"
          id="inputGroupSelect01"
          v-model="month"
          @change="getdata()"
        >
          <option :value="m.id" v-for="m in months" :key="m.id">
            {{ m.name }}
          </option>
        </select>
      </div>
      
      <div data-bs-toggle="modal" data-bs-target="#exampleModal">
        <div style="text-align: right">
          <i
            class="fa fa-plus-circle"
            style="font-size: 36px; color: black"
          ></i>
        </div>
      </div>
      <table class="row table table-striped">
        <div class="col-12" v-if="list.length > 0">
        <thead >
          <tr>
            <th scope="col" style="width:50%">วันที่</th>
            <th scope="col" style="width:50%">รายละเอียด</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(l, i) in list" :key="i">
            <th scope="row" :class="
            l.class
          ">{{ convertdate(l.date) }}</th>
            <td :class="
            l.class
          ">{{ l.title }}</td>
            <td>
              <button
                @click="deletecalendar(l.calendar_id)"
                type="button"
                class="btn btn-danger btn-sm"
              >
                <i
                  class="fa fa-trash"
                  style="font-size: 16px; color: white"
                ></i>
              </button>
            </td>
          </tr>
        </tbody>
        </div>
        <div v-else>
          <h5 style="text-align:center">ไม่พบข้อมูล</h5></div>
      </table>

      <!-- <div class="list-group mt-3">
        <li
          
          href="#"
          :class="
            'list-group-item d-flex justify-content-between align-items-center ' +
            l.style
          "
        >
          {{ convertdate(l.date) }}
          <a :href="'/info/' + l.content_id"
            ><span class="badge badge-pill"
              ><i class="fa fa-edit" style="font-size: 24px; color: black"></i
            ></span>
          </a>
        </li>
      </div> -->
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label for="inputPassword" class="col-sm-2 col-form-label"
                >วันที่</label
              >
              <div class="col-sm-6">
                <date-picker
                  v-model="date"
                  format="DD/MM/YYYY"
                  valueType="format"
                ></date-picker>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPassword" class="col-sm-2 col-form-label"
                >ประเภท</label
              >
              <div class="col-sm-10">
                <select
                  class="custom-select"
                  id="inputGroupSelect01"
                  v-model="type"
                >
                  <option :value="t.day" v-for="t in types" :key="t.id">
                    {{ t.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label for="inputPassword" class="col-sm-2 col-form-label"
                >Description</label
              >
              <div class="col-sm-10">
                <textarea
                  required
                  v-model="description"
                  type="text"
                  class="form-control"
                  id="inputPassword"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              id="closecalendarlist"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button type="button" class="btn btn-success" @click="save()">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConvertService from "../services/ConvertService";
import Calendar from "../services/Calendar";
import TypeService from "../services/TypeService";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      list: [],
      colour: [
        "list-group-item-primary",
        "list-group-item-secondary",
        "list-group-item-success",
        "list-group-item-danger",
        "list-group-item-warning",
        "list-group-item-info",
        "list-group-item-light",
        "list-group-item-dark]",
      ],
      json: {},
      month: "",
      months: [
        {
          id: 1,
          name: "January",
        },
        {
          id: 2,
          name: "February",
        },
        {
          id: 3,
          name: "March",
        },
        {
          id: 4,
          name: "April",
        },
        {
          id: 5,
          name: "May",
        },
        {
          id: 6,
          name: "June",
        },
        {
          id: 7,
          name: "July",
        },
        {
          id: 8,
          name: "Angust",
        },
        {
          id: 9,
          name: "September",
        },
        {
          id: 10,
          name: "October",
        },
        {
          id: 11,
          name: "November",
        },
        {
          id: 12,
          name: "December",
        },
      ],
      types: [],
      type: "",
      date: "",
      description: "",
    };
  },
  methods: {
    deletecalendar(id) {
      console.log(id);
      Calendar.deleteCalendar(id).then(() => {
        this.loaddata();
      });
    },
    getcolor(day) {
      var color = "";
      if (day == "Mon") {
        color = "bg-yellow-500 text-white";
      } else if (day == "Tue") {
        color = "bg-pink-500 text-white";
      } else if (day == "Wed") {
        color = "bg-green-500 text-white";
      } else if (day == "Thu") {
        color = "bg-orange-500 text-white";
      } else if (day == "Fri") {
        color = "bg-blue-500 text-white";
      } else if (day == "Sat") {
        color = "bg-purple-500 text-white";
      } else if (day == "Sun") {
        color = "bg-red-500 text-white";
      }
      return color;
    },
    getdata() {
      this.loaddata();
    },
    save() {
      if (!this.date) {
        alert("เลือกวันที่");
      } else if (!this.description) {
        alert("กรอกรายละเอียด");
      } else {
        var d = this.generatedate(this.date);
        var t = new Date(d);
        var days = t.toLocaleString("en-us", { weekday: "short" });
        var day = this.getcolor(days);
        console.log(this.type);
        var create = {
          date: d,
          title: this.description,
          class: day,
        };
        // console.log(create);
        Calendar.createCalendar(create).then(() => {
          setTimeout(function () {
          location.reload();
          this.loaddata();
        }, 500);
        });
        var current = new Date();
        if (this.type > 0) {
          for (let u = 0; u < 100; u++) {
            var dat = new Date(t);
            dat.setDate(dat.getDate() + this.type);
            // console.log(dat);
            t = dat;
            console.log(t.getFullYear(), current.getFullYear());
            if (t.getFullYear() == current.getFullYear()) {
              var daysround = t.toLocaleString("en-us", { weekday: "short" });
              var dayround = this.getcolor(daysround);
              // console.log(this.type);
              var createround = {
                date: t,
                title: this.description,
                class: dayround,
              };
              // console.log(createround);
              Calendar.createCalendar(createround).then(() => {});
            }else{
setTimeout(function () {
          location.reload();
          this.loaddata();
        }, 500);
            }
          }
        }
      }
    },
    generatedate(date) {
      var d = date.split("/");
      var value = d[2] + "-" + d[1] + "-" + d[0];
      return value;
    },
    gettypes() {
      TypeService.getTypes().then((res) => {
        // console.log(res.data);
        this.types = res.data;
      });
    },
    loaddata() {
      Calendar.getCalendars(this.month).then((res) => {
        this.list = res.data;
        // console.log(res.data);
      });
    },
    convertdate(date) {
      return ConvertService.convertdatetothai(date, "en-EN");
    },
    start() {
      // localStorage.removeItem("keys");
      // var item = localStorage.getItem("keys");
      // this.json = JSON.parse(item);
      // console.log(this.json);
      // if (this.json) {
      this.loaddata();
      // }
    },
    getdatenow(){
      var d = new Date();
      var date = d.getDate();
    this.month = d.getMonth() + 1;
    var year = d.getFullYear();
    var month = this.month;
if (String(date).length == 1) {
      date = "0" + date;
    }
    if (String(month).length == 1) {
      month = "0" + month;
    }
    this.date = date + "/" + month + "/" + year;
    }
  },

  mounted() {
   this.getdatenow()
    this.start();
    this.gettypes();
    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
