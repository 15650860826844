import http from "../http-common";

class CalendarDataService {
  getCalendars(name) {
    return http.get('/calendar?name='+name);
  }
  getCalendar(id) {
    return http.get(`/calendar/${id}`);
  }
  createCalendar(data) {
    return http.post('/calendar', data);
  }
  updateCalendar(id,data) {
    return http.put(`/calendar/${id}`, data);
  }
  deleteCalendar(id) {
    return http.delete(`/calendar/${id}`);
  }
}

export default new CalendarDataService();