<template>
  <div class="container">
    <div class="mt-5">
      <FullCalendar class="demo-app-calendar" :options="calendarOptions">
        <template v-slot:eventContent="arg">
          <i>{{ arg.event.title }} </i>
        </template>
      </FullCalendar>
      <div class="col mb-3 mt-3" style="text-align: right">
        <a>
          <button
      id="AddEvent"
      type="button"
      class="btn btn-primary"
      data-bs-toggle="modal"
      data-bs-target="#AddUser"
      style="display: none"
    >
      Launch demo modal
    </button></a>
      </div>
      <!-- <table class="table table-bordered">
      <thead>
        <tr class="table-active">
          <th scope="col" colspan="6" style="text-align: center;">รายชื่อหมอนวด</th>
        </tr>
      </thead>
      <thead>
        <tr class="table-active">
          <th scope="col">ลำดับที่</th>
          <th scope="col">ชื่อ-นามสกุล</th>
          <th scope="col">รอบการบริการ</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(l, i) in list" :key="i">
          <td>
            {{ i + 1 }}
          </td>
          <td>
            {{ l.firstname }} {{ l.lastname }}
          </td>
          <td>
            {{ l.dentistCourseId }}
          </td>
          <td>
            <a @click="getid(l.id)">
              <button type="button" class="btn btn-warning" data-bs-toggle="modal" data-bs-target="#AddUser">
                <i class="fa fa-edit"></i></button></a>
          </td>
        </tr>
      </tbody>
    </table> -->

      <!-- Modal -->
      <!-- <div class="modal fade" id="AddUser" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>

          </div>
          <div class="modal-body">
            <form>
              <div class="card-body">
                <div class="form-group">
                  <label>วันที่เข้ารับการบริการ:</label>
                  <input @change="searchdoctor" type="date" class="form-control datetimepicker-input"
                    data-bs-target="#reservationdate" v-model="user.date">
                  <div class="input-group-append" data-bs-target="#reservationdate" data-bs-toggle="datetimepicker">
                  </div>
                </div>
                <div class="form-group mt-3">
                  <label for="password">หมอ</label>
                  <div class="form-group">
                    <div class="custom-control custom-checkbox" v-for="(i, r) in doctors" :key="r" :value="i.id">
                      <input @change="searchtime" class="form-check-input" type="radio" name="radio1" :id="i.id"
                        :value="i.id" v-model="doctor_id">
                      <label :for="i.id" class="form-check-label">{{ i.firstname }} {{ i.lastname }}</label>
                    </div>
                    <div v-if="doctors.length == 0">ไม่พบหมอที่ให้บริการวันที่เลือก</div>
                  </div>
                </div>
                <div class="form-group mt-3">
                  <label for="password">รอบการบริการ</label>
                  <div class="form-group">
                    <div class="custom-control custom-checkbox" v-for="(i, r) in courses" :key="r" :value="i.id">
                      <input class="form-check-input" type="radio" name="radio2" :id="'r'+i.id" :value="i.id" v-model="course_id">
                      <label :for="'r'+i.id" class="custom-check-label">{{ gettime(i.time_start, i.time_end) }}</label>
                    </div>
                  </div>
                  <div v-if="courses.length == 0">ไม่พบรอบการให้บริการ</div>

                </div>

              </div>
            </form>
          </div>
          <div class="modal-footer mt-3">
            <button type="button" class="btn btn-success" @click="save()">
              บันทึก
            </button>
            <button id="closeduser" type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div> -->
    <div
      class="modal fade"
      id="AddUser"
      tabindex="-1"
      role="dialog"
      aria-labelledby="AddUserLabel"
      aria-hidden="true"
    >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
            </div>
            <div class="modal-body">
              <div class="form-group row">
              <label for="staticEmail" class="col-sm-12 col-form-label"
                >Title
              </label>
              <div class="col-sm-10">
                <input
                  id="date_calendar"
                  class="form-control"
                  v-model="book.title"
                />
               
              </div>
            </div>
            </div>
            <div class="modal-footer mt-3">
                <button type="button" class="btn btn-success" @click="save()">
                บันทึก
              </button>
              <button type="button" class="btn btn-danger" @click="deleteplan()" v-if="user_id != 0">
                ลบ
              </button>
              <button
                id="closeduser"
                type="button"
                class="btn btn-secondary"
              data-bs-dismiss="modal"
              aria-label="Close"
              >
                ปิด
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src='../assets/locale/th'></script>
 
<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import PlanService from "../services/PlanService";
import esLocale from "@fullcalendar/core/locales/th";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  components: {
    FullCalendar, // make the <FullCalendar> tag available
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        initialView: "dayGridMonth",
        dateClick: this.handleDateClick,
        weekends: true,
        eventClick: this.handleEventClick,
        views: {
          dayGridMonth: {
            dayHeaderFormat: {
              weekday: "long",
            },
          },
        },
        headerToolbar: {
          left: "prev,next",
          center: "title",
          right: "timeGridWeek,dayGridMonth",
        },
        height: 850,
        locale: esLocale,
        scrollTime: "08:00",
        omitZeroMinute: false,
        slotLabelFormat: {
          hour: "numeric",
          minute: "2-digit",
          omitZeroMinute: false,
          hour12: false,
        },
        events: [],
      },
      alltoken: [],
      book: {},
      events: [],
      list: [],
      user_id: 0,
      title: "",
      courses: [],
      course_id: [],
      days: [],
      doctor_id: "",
      day: [
        {
          id: 1,
          nameth: "วันจันทร์",
          nameen: "MON",
        },
        {
          id: 2,
          nameth: "วันอังคาร",
          nameen: "TUE",
        },
        {
          id: 3,
          nameth: "วันพุธ",
          nameen: "WED",
        },
        {
          id: 4,
          nameth: "วันพฤหัสบดี",
          nameen: "THUR",
        },
        {
          id: 5,
          nameth: "วันศุกร์",
          nameen: "FRI",
        },
      ],
      doctors: [],
      header: "",
      allday: true,
      head: "",
      shphId: "",
      shphName: "",
      mapId: "",
      time: {},
    };
  },
  mounted() {
    this.getEvents();
  },
  methods: {
    getEvents() {
      PlanService.getplans().then((res) => {
        this.calendarOptions.events = res.data;
        console.log(res.data);
        // this.calendarOptions.events = this.events
        //   this.calendarOptions.events.push({
        //   title:'test',
        //   date:'2023-09-01'
        // })
        // console.log(this.calendarOptions.events);
      });
    },
    handleDateClick: function (arg) {
      // console.log(arg);
      var breaktime = new Date(arg.dateStr);

      var d =
        breaktime.getFullYear() +
        "-" +
        (parseInt(breaktime.getMonth()) + 1).toString().padStart(2, "0") +
        "-" +
        breaktime.getDate().toString().padStart(2, "0");
      console.log(d);
        var now = new Date();
        var selectdate = new Date(d);

        now =
          now.getFullYear() +
          "-" +
          (parseInt(now.getMonth()).toString().padStart(2, "0") + 1) +
          "-" +
          now.getDate().toString().padStart(2, "0");
        now = new Date(now);

        console.log(selectdate, now);
        this.title =
          "เพิ่มข้อมูลแพลน " +
          breaktime.toLocaleDateString("th-TH", {
            year: "numeric",
            month: "long",
            day: "numeric",
          });
          if (this.currentUser) {
        this.getid(0,arg.dateStr);
        document.getElementById("AddEvent").click();
            
          }
      
    },
    handleEventClick(clickInfo) {
      console.log(clickInfo.event.groupId);
      this.mapId = clickInfo.event.groupId;
      var id = clickInfo.event.id;

      // console.log(this.mapId);
      var breaktime = new Date(clickInfo.event.start);

      var d =
        breaktime.getFullYear() +
        "-" +
        (parseInt(breaktime.getMonth()) + 1).toString().padStart(2, "0") +
        "-" +
        breaktime.getDate().toString().padStart(2, "0");
      var now = new Date();
      var selectdate = new Date(d);

      now =
        now.getFullYear() +
        "-" +
        (parseInt(now.getMonth()) + 1).toString().padStart(2, "0") +
        "-" +
        now.getDate().toString().padStart(2, "0");
      now = new Date(now);

      // console.log(selectdate,now);

      if (breaktime.getHours() != 12) {
        this.header = breaktime.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
        this.title =
          "แก้ไขข้อมูลแพลน " +
          breaktime.toLocaleDateString("th-TH", {
            year: "numeric",
            month: "long",
            day: "numeric",
          });
        if (breaktime.getHours() != 0) {
          // this.title +=
          //   " เวลา " + this.timeformat(breaktime.toLocaleTimeString("th-TH"));
          // this.header +=
          //   " เวลา " + this.timeformat(breaktime.toLocaleTimeString("th-TH"));
          this.allday = false;
        } else {
          this.allday = true;
        }

        if (this.currentUser) {
        this.getid(id,'');
        document.getElementById("AddEvent").click();
        }
      }
      // const result = date.toLocaleDateString('th-TH', {
      //   year: 'numeric',
      //   month: 'long',
      //   day: 'numeric',
      // })
      //       if (confirm(`ยืนการการลบ '${result}'`)) {
      //         // clickInfo.event.remove()
      //         var event = {
      //         status:0
      //       }
      //       PlanService.updateevent(clickInfo.event.id,event).then(()=>{
      //         this.getEvents()
      //       })
      //       }
    },
    timeformat(time) {
      time = time.split(":");
      return time[0] + "." + time[1] + " น.";
    },
    gettime(start, end) {
      var s = start.split(":");
      var e = end.split(":");
      var value = s[0] + "." + s[1] + "-" + e[0] + "." + e[1] + " น.";
      return value;
    },
    getid(id,date) {
      this.alltoken = [];
      // console.log(id);
      this.user_id = id;
      if (id != 0) {
        // console.log(this.user_id);
        PlanService.getplan(id).then((res) => {
          // console.log(res.data);
          this.book = res.data;
          console.log(this.book);
        });
      } else {
        this.course_id = [];
        this.days = [];
        this.book = {};
        this.book.date = date
      }
    },
    deleteplan() {
      // var userdata = {
      //   noti: this.book.noti,
      //   title: this.book.title,
      //   userId: this.book.userId,
      // };
      PlanService.deleteplan(this.user_id).then(() => {
        document.getElementById("closeduser").click();
          console.log(1);
          this.getEvents();
          //       setTimeout(function () {
          //   location.reload();
          // }, 500);
          // window.scrollTo(0, 0);
      });
    },
    save() {
      var date = new Date(this.book.date)
      var day = date.toLocaleString("en-us", { weekday: "short" });
        // console.log(day);
        if (day == "Mon") {
          this.book.backgroundColor = "#ffff00";
        } else if (day == "Tue") {
          this.book.backgroundColor = "#ffc0cb";
        } else if (day == "Wed") {
          this.book.backgroundColor = "#008000";
        } else if (day == "Thu") {
          this.book.backgroundColor = "#ffa500";
        } else if (day == "Fri") {
          this.book.backgroundColor = "#00bfff";
        } else if (day == "Sat") {
          this.book.backgroundColor = "#800080";
        } else if (day == "Sun") {
          this.book.backgroundColor = "#ff0000";
        }
      var userdata = {
        title: this.book.title,
        date:this.book.date,
            backgroundColor: this.book.backgroundColor,
            borderColor: this.book.backgroundColor,
            createdBy: this.currentUser.id,
      };
      console.log(userdata);
      if (this.user_id == 0) {
        PlanService.createplan(userdata).then(() => {
        document.getElementById("closeduser").click();
          this.getEvents();
        });
      }else{
      PlanService.updateplan(this.user_id, userdata).then(() => {
        document.getElementById("closeduser").click();
          this.getEvents();
          //       setTimeout(function () {
          //   location.reload();
          // }, 500);
          // window.scrollTo(0, 0);
      });
    }
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vertical-menu {
  background-color: #eee;
}

.vertical-menu a {
  background-color: #eee;
  color: black;
  display: block;
  padding: 12px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #ccc;
}

.vertical-menu a.active {
  background-color: #04aa6d;
  color: white;
}

.card {
  margin: 0 auto;
  /* Added */
  float: none;
  /* Added */
  margin-bottom: 10px;
  /* Added */
}

body {
  background-color: gray;
}
</style>
