<template>
  <div class="hello">
    <div class="container mt-5">
    <div style="text-align: right">
      <button v-if="currentUser" style="vertical-align: middle;" id="AddDataset" @click="getid(0)" type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#exampleModal">
        <i class="fa fa-plus" aria-hidden="true"></i>
</button>
<a href="https://scholar.google.com/citations?user=HyrIVzQAAAAJ&hl=th" target="_blank"><button style="vertical-align: middle;" type="button" class="btn btn-primary">
        <i class="fa fa-link" aria-hidden="true"></i>
</button></a>
         </div
      >
      <table class="table table-striped">
  <thead>
    <tr>
      <th scope="col">Title</th>
      <th scope="col">Conference</th>
      <th scope="col">Database</th>
      <th scope="col">Dataset</th>
      <th scope="col">Year</th>
      <th v-if="currentUser" scope="col"></th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="(l, i) in list" :key="i">
      <td scope="row" style="vertical-align: middle;">{{l.title}}</td>
      <td scope="row" style="vertical-align: middle;">{{l.conference}} <br/>{{getdate(l.date)}}</td>
      <td scope="row" style="vertical-align: middle;">{{l.databasename}}</td>
      <td scope="row" style="vertical-align: middle;"><span v-for="d in l.datasetname" :key="d.id">{{d}}&nbsp;</span></td>
      <td scope="row" style="vertical-align: middle;">{{l.year}}</td>
      <td v-if="currentUser" style="vertical-align: middle;"> <div class="form-check form-switch">
  <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="l.status" @change="savestatus(l.id,l.status)">
</div></td>
      <td scope="row" style="vertical-align: middle;">
        <button v-if="currentUser" type="button" class="btn btn-warning" @click="getid(l.id)" data-bs-toggle="modal" data-bs-target="#exampleModal"><i class="fa fa-pencil" aria-hidden="true"></i></button>
        <a :href="l.link" target="_blank"><button type="button" class="btn btn-info" ><i style="vertical-align: middle;" class="fa fa-link" aria-hidden="true"></i></button></a>
      </td>
    </tr>
  </tbody>
</table>
    </div>

    <!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{title}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group row">
              <label for="staticEmail" class="col-sm-12 col-form-label"
                >Title
              </label>
              <div class="col-sm-12">
                <textarea
                rows="3"
                  id="date_calendar"
                  class="form-control"
                  v-model="data.title"
                ></textarea>
               
              </div>
            </div>
            <div class="form-group row">
              <label for="staticEmail" class="col-sm-12 col-form-label"
                >Date
              </label>
              <div class="col-sm-12">
                <input
                type="date"
                  id="date_calendar"
                  class="form-control"
                  v-model="data.date"
                />
                
              </div>
            </div>
            
            <div class="form-group row">
              <label for="staticEmail" class="col-sm-12 col-form-label"
                >Conference
              </label>
              <div class="col-sm-12">
                <textarea
                rows="3"
                  id="date_calendar"
                  class="form-control"
                  v-model="data.conference"
                ></textarea>
               
              </div>
            </div>
            <div class="form-group row">
              <label for="staticEmail" class="col-sm-12 col-form-label"
                >Database
              </label>
              <div class="col-sm-12">
                <select class="form-select" v-model="data.databaseId">
  <option v-for="(db, i) in databaseds" :key="i" :value="db.id">{{db.name}}</option>
</select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-12 col-form-label"
                >Dataset
              </label>
              <div class="col-sm-12">
                <div class="col-12" v-for="m in datasets" :key="m.id">
          <div class="form-group" style="text-align: left">
            <label>
              <input
                type="checkbox"
                v-model="datasets_id"
                :id="m.name"
                :value="m.id"
              />
              <span> {{ m.name }}</span>
            </label>
          </div>
        </div>
              </div>
            </div>
            <!-- <div class="form-group row">
              <label for="staticEmail" class="col-sm-12 col-form-label"
                >Year
              </label>
              <div class="col-sm-12">
                <select class="form-select" v-model="data.year">
  <option v-for="(db, i) in years" :key="i" :value="db">{{db}}</option>
</select>
              </div>
            </div> -->
            <div class="form-group row">
              <label for="staticEmail" class="col-sm-12 col-form-label"
                >Link
              </label>
              <div class="col-sm-12">
                <textarea
                rows="2"
                  id="date_calendar"
                  class="form-control"
                  v-model="data.link"
                ></textarea>
               
              </div>
            </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" @click="save()">Save</button>
        <button type="button" id="close" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
  </div>

  
</template>

<script>
import ResearchService from "../services/ResearchService";
import DatabasedService from "../services/DatabasedService";
import DatasetService from "../services/DatasetService";

export default {
  data() {
    return {
      data:{},
      id:0,
      title:'',
      list: [],
      json: {},
      databaseds:[],
      datasets:[],
      datasets_id:[],
      years:[]
    };
  },
  methods: {
    getdate(date){
      var dateresult = ''
      if (date) {
         dateresult = new Date(date)
      dateresult = dateresult.toLocaleDateString("en-EN", {
            year: "numeric",
            month: "short",
            day: "numeric",
          });
      }
          return dateresult
    },
    gotolink(link){
      window.location = link
    },
    getyear(){
      var year = new Date()
      var yearcurr = year.getFullYear()
      // this.years.push(2022)
      var count = (yearcurr-2021)+1
      // console.log(count);
      for (let c = 0; c < count; c++) {
        this.years.push(2021+c)
      }
      // console.log(this.years);
    },
    getdatabase(){
      DatabasedService.getdatabaseds(1).then((res)=>{
this.databaseds = res.data
// console.log(res.data);
      })
    },
    getdataset(){
      DatasetService.getdatasets(1).then((res)=>{
        // console.log(res.data);
this.datasets = res.data
      })
    },
    savestatus(id,status){
      var data = {
        status:status
      }
      ResearchService.updatestatus(id,data).then(() => {
          // alert('บันทึกสำเร็จ')
          this.getdata();
        });
      
// console.log(id,active);
    },
    getid(id){

    this.getdatabase()
    this.getdataset()
      this.id = id
      // console.log(this.id);
      if (this.id != 0) {
        ResearchService.getresearch(this.id).then((res)=>{
        this.data = res.data
        this.datasets_id = JSON.parse(res.data.datasetId)
        var date = new Date(res.data.date)
        
        this.data.date  = date.getFullYear()+'-'+(("0" + (date.getMonth() + 1)).slice(-2))+'-'+(("0" + (date.getDate())).slice(-2))
        console.log(this.data.date);
      })
      }else{
        this.id = 0
this.data = {}
this.datasets_id = []
      }
     
    },
    save(){
      if (this.data.title == null || this.data.title == '') {
        alert('กรุณากรอก Title')
      }else if(this.data.databaseId == null || this.data.databaseId == '') {
        alert('กรุณาเลือก Database')
      }else if(this.datasets_id.length == 0) {
        alert('กรุณาเลือก Dataset')
      }else if(this.data.conference == null || this.data.conference == '') {
        alert('กรุณากรอก Conference')
      }else if(this.data.date == null || this.data.date == '') {
        alert('กรุณากรอกวันที่เผยแพร่')
      }else if(this.data.link == null || this.data.link == '') {
        alert('กรุณากรอก Link')
      }else{
        var date = new Date(this.data.date)
var data = {
  title : this.data.title,
  databaseId : this.data.databaseId,
  datasetId : this.datasets_id,
  year : date.getFullYear(),
  link:this.data.link,
  status:1,
  date:this.data.date,
  conference:this.data.conference,
}
// console.log(data);
if (this.id != 0) {
  ResearchService.updateresearch(this.id,data).then(()=>{
    document.getElementById("close").click();
    this.getdata()
  })
}else{
  ResearchService.createresearch(data).then(()=>{
  document.getElementById("close").click();
  this.getdata()
  })
}
      }
    },
    getdata() {
      ResearchService.getresearchs('').then((res)=>{
        console.log(res.data);
        this.list = res.data
      })
    },
  },

  mounted() {  
    this.getdata();    
    this.getdatabase()
    this.getdataset()
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
