import http from "../http-common";

class TodoListService {
  getTodoLists(date) {
    return http.get('/todolists?date='+date);
  }
  getTodoList(id) {
    return http.get(`/todolists/${id}`);
  }
  createTodoList(data) {
    return http.post('/todolists', data);
  }
  updateTodoList(id,data) {
    return http.put(`/todolists/${id}`, data);
  }
  updatechecked(id,data) {
    return http.put(`/todolists/updatechecked/${id}`, data);
  }
  deleteTodoList(id) {
    return http.delete(`/todolists/${id}`);
  }
  
}

export default new TodoListService();