import http from "../http-common";

class ResearchService {
  getresearchs(name) {
    return http.get('/research?name='+name);
  }
  getresearch(id) {
    return http.get(`/research/${id}`);
  }
  createresearch(data) {
    return http.post('/research', data);
  }
  updateresearch(id,data) {
    return http.put(`/research/${id}`, data);
  }
  updatestatus(id,data) {
    return http.put(`/research/updatestatus/${id}`, data);
  }
  deleteresearch(id) {
    return http.delete(`/research/${id}`);
  }
}

export default new ResearchService();