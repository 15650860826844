import http from "../http-common";

class ContentDataService {
  getContents() {
    return http.get('/content');
  }
  getContent(id) {
    return http.get(`/content/${id}`);
  }
  createContent(data) {
    return http.post('/content', data);
  }
  updateContent(id,data) {
    return http.put(`/content/${id}`, data);
  }
}

export default new ContentDataService();