import Vue from "vue";
import Router from "vue-router";
import List from './components/List.vue';
import Special from './components/Special.vue';
import Info from './components/Info.vue';
import InfoSpecial from './components/InfoSpecial.vue';
import Calendar from './components/Calendar.vue';
import ReadingPlan from './components/ReadingPlan.vue';
// import DaPlan from './components/DaPlan.vue';
import ListCalendar from './components/ListCalendar.vue';
import TodoLists from './components/TodoLists.vue';
import plans from './components/plans.vue';
import Login from './components/Login.vue';
import Research from './components/Research.vue';
import Datasets from './components/Datasets.vue';
import Databased from './components/Databased.vue';

Vue.use(Router);
const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/list",
      alias: "/list",
      name: "list",
      component: List
    },
    {
      path: "/listcalendar",
      alias: "/listcalendar",
      name: "listcalendar",
      component: ListCalendar
    },
    {
      path: "/special",
      alias: "/special",
      name: "special",
      component: Special
    },
    {
      path: "/info/:id",
      alias: "/info",
      name: "info",
      component: Info
    },
    {
      path: "/infospecial/:id",
      alias: "/infospecial",
      name: "infospecial",
      component: InfoSpecial
    },
    // {
    //   path: "/",
    //   alias: "/calendar",
    //   name: "calendar",
    //   component: Calendar
    // },
    {
      path: "/home",
      alias: "/calendar",
      name: "calendar",
      component: Calendar
    },
    {
      path: "/readingplan",
      alias: "/readingplan",
      name: "readingplan",
      component: ReadingPlan
    },
    {
      path: "/todolists",
      alias: "/todolists",
      name: "todolists",
      component: TodoLists
    },
    {
      path: "/plans",
      alias: "/plans",
      name: "plans",
      component: plans
    },
    {
      path: "/login",
      alias: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/",
      alias: "/research",
      name: "research",
      component: Research
    },
    {
      path: "/research",
      alias: "/research",
      name: "research",
      component: Research
    },
    {
      path: "/dataset",
      alias: "/dataset",
      name: "dataset",
      component: Datasets
    },
    {
      path: "/database",
      alias: "/database",
      name: "database",
      component: Databased
    },
  ]
});

router.beforeEach((to, from, next) => {
  var page = to.path.split("/")
  var qrcode = ''
  if (page[1] == 'plans') {
    qrcode = to.path
  }
  const publicPages = ['/', '/login', qrcode,'/research'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('planoum');
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/');
    // next();
  } else {
    next();
  }
});

export default router;
