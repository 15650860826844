import http from "../http-common";

class SpecialDataService {
  getSpecials() {
    return http.get('/special');
  }
  getSpecial(id) {
    return http.get(`/special/${id}`);
  }
  createSpecial(data) {
    return http.post('/special', data);
  }
  updateSpecial(id,data) {
    return http.put(`/special/${id}`, data);
  }
  daleteSpecial(id) {
    return http.delete(`/special/${id}`);
  }
}

export default new SpecialDataService();